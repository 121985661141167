import textblockTypes from './textblock.types'

const INITIAL_STATE = {
    textblocktext: null
}

const TEXT_LOOKUP = {
    0: ['Varning för annan fara'],
    1: ['Varning för lekande barn'],
    2: ['Älgskylt', 'Även dekal (ej i egen bil)'],
    3: ['Vägarbete'],
    4: ['Vägbulor'],
    5: ['30-skylt eller 40-skylt'],
    6: ['50-skylt eller 60-skylt'],
    7: ['70-skylt eller 80-skylt'],
    8: ['90-skylt eller 100-skylt'],
    9: ['110-skylt eller 120-skylt'],
    10: ['Bensinmack'],
    11: ['Rastplats'],
    12: ['Campingplats'],
    13: ['Information'],
    14: ['Restaurang'],
    15: ['Rondellskylt'],
    16: ['Löpare', 'Måste springa'],
    17: ['Fartkamera'],
    18: ['Fågel', 'Valfri art'],
    19: ['Får', 'Oavsett färg'],
    20: ['Ko eller tjur' , 'Oavsett färg'],
    21: ['Häst', 'Valfri färg'],
    22: ['Hund', 'Valfri art'],
    23: ['Roadkill', 'Överkört djur på/vid vägen'],
    24: ['Kundvagn'],
    25: ['Polisfordon', 'Bil, buss, MC eller häst'],
    26: ['Ambulans', 'Även sjuktransport'],
    27: ['Släpvagn', 'Täckt eller öppen'],
    28: ['Husvagn', 'Inte husbil'],
    29: ['Motorcykel', 'Även moped'],
    30: ['Cyklist', 'Någon måste sitta på cykeln'],
    31: ['Barnvagn'],
    32: ['Traktor', 'Oavsett färg'],
    33: ['Kyrka'],
    34: ['Flyplan', 'I luften eller på marken'],
    35: ['Båt', 'Segelbåt, motorbåt, kanot. Även på land'],
    36: ['Stoppskylt'],
    37: ['Älgtorn'],
    38: ['Postlåda', 'Endast gula lådor'],
    39: ['Grävmaskin', 'Måste ha skopa'],
    40: ['Flagga eller vimpel', 'För det land man är i'],
    41: ['Glasigloo', 'Även fyrkantiga, tråkiga'],
    42: ['Tankstation', 'Bensinmack'],
    43: ['Vindkraftverk'],
    44: ['Brandbil', 'Även andra brandfordon.'],
    45: ['Fyllbult', 'Man eller kvinna. Helst med öl'],
    46: ['Fiskare'],
    47: ['Katt'],
    48: ['Vilt', 'Rådjur, älg, ren, hjort...'],
    49: ['Helikopter', 'I luften eller på marken'],
}

const textblockReducer = (state=INITIAL_STATE, action) => {
    switch(action.type){
        case textblockTypes.SET_TEXTBLOCK_TEXT:
            const blockId = String(action.payload);
            let text = null;
            if(Object.keys(TEXT_LOOKUP).includes(blockId)){
                text = TEXT_LOOKUP[blockId]
            }

            return {
                ...state,
                textblocktext: text
            }

        default:
            return state;
    }
};

export default textblockReducer