// Sprite sheet
export const SPRITE_SIZE = 400;
export const NUMBER_OF_COLUMNS = 5;
export const IMAGE_COUNT = 44;
export const BINGO_ROW = 12;
export const CROSS_X = 4, CROSS_Y = 11;
export const EXTREME_IMAGES = [44, 45, 46, 47, 48, 49];

// Bingo
export const BINGO_FLASH_MS = 600;

// **** COMPONENTS ****
// Header
export const HEADER_HEIGHT_PX = 60;
// TextBlock
export const TEXTBLOCK_HEIGHT_PX = 40;
export const TEXTBLOCK_TIMEOUT_MS = 5000;
// Ads
export const ADS_HEIGTH_PX = 100;
export const ADS_WIDTH_PX = 170;

export const BOARD_OFFSET_PX = HEADER_HEIGHT_PX + ADS_HEIGTH_PX + TEXTBLOCK_HEIGHT_PX;

