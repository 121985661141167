import { EXTREME_IMAGES, IMAGE_COUNT } from "../../assets/const";

const shuffleArray = (array) => {
    for (let i = array.length - 1; i > 0; i--) {
        const j = Math.floor(Math.random() * (i + 1));
        [array[i], array[j]] = [array[j], array[i]];
    }
}

const checkBingo = (blocks, checkedArray, bingoArray, verbose) => {
    if (blocks.every(idx => checkedArray[idx])) {
        //bingo!
        blocks.forEach((idx, cnt) => bingoArray[idx] = cnt + 1);
    }
}


export const getShuffledBoard = (extreme) => {
    //Initialize with all blocks
    const allItems = initArray(IMAGE_COUNT, 0, true);

    // shuffle
    shuffleArray(allItems);

    //reduce to 25
    let board = allItems.slice(0, 25);

    // Insert extreme images, if extreme mode
    if (extreme) {
        const extreme_images = EXTREME_IMAGES;
        shuffleArray(extreme_images);
        for (let i = 0; i < 5; i++) {
            board[i * 6] = extreme_images[i];
        }
    }

    return board;
}

/**
 * initializes an array of length len with values val. If increase == true, the array will be [val, val+1, val+2...]
 * @param {int} len 
 * @param {*} val 
 * @param {int} increase 
 */
export const initArray = (len, val, increase) => {
    const arr = []
    for (let i = 0; i < len; i++) {
        if (increase) {
            arr.push(val + i);
        } else {
            arr.push(val)
        }
    }
    return arr;
}

export const indexToRowCol = (index) => {
    const row = Math.floor(index / 5);
    const col = index - row * 5;
    return { row, col }
}

/**
 * Checks selected index for bingo
 * @param {array} blocks 
 * @param {int} index 
 * @returns bingo (true/false) in {h: horizontal, v: vertical, d: diagonal down, u diagonal up}
 */
export const checkForBingo = (blocks, index) => {
    const bingoArray = initArray(25, 0);
    if (!blocks[index]) {
        // Don't check unclick
        return bingoArray;
    }

    // Set up where to look for bingo based on clicked block row and col
    const { row, col } = indexToRowCol(index);
    const hor = [], ver = [];
    for (let i = 0; i < 5; i++) {
        hor.push(row * 5 + i);
        ver.push(i * 5 + col)
    }
    const diag_down = [0, 6, 12, 18, 24];
    const diag_up = [20, 16, 12, 8, 4];

    // Check for bingo. Only check diagonal if that includes clicked block
    const toCheck = [ver, hor];
    if (diag_down.includes(index)) { toCheck.push(diag_down) };
    if (diag_up.includes(index)) { toCheck.push(diag_up) };
    toCheck.forEach(arr => checkBingo(arr, blocks, bingoArray));

    return bingoArray;
}