import menuActionTypes from "./menu.types";

const INITIAL_STATE = {
    menuVisible: false
};


const menuReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case menuActionTypes.TOGGLE_MENU_VISIBLE:
            return {
                ...state,
                menuVisible: !state.menuVisible
            };
        case menuActionTypes.HIDE_MENU:
            return {
                ...state,
                menuVisible: false
            }
        default:
            return state;
    }
}

export default menuReducer;