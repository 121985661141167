import React, { Suspense } from 'react'
import { ADS_HEIGTH_PX, HEADER_HEIGHT_PX, TEXTBLOCK_HEIGHT_PX } from '../../assets/const';

import styled from 'styled-components';
import InfoModal from '../../components/info-modal/info-modal.component';

const Ads = React.lazy(() => import('../../components/ads/ads.component'))
const Board = React.lazy(() => import('../../components/board/board.component'))
const Header = React.lazy(() => import('../../components/header/header.component'))
const MenuOverlay = React.lazy(() => import('../../components/menu-overlay/menu-overlay.component'))
const InfoBlock = React.lazy(() => import('../../components/info-block/info-block.component'))

const HomeDiv = styled.div`
    display:flex;
    flex-direction: ${prop => prop.landscape ? 'row' : 'column'};
    justify-content: center;
`

const FlexDiv = styled.div`
    display: flex;
    flex-direction: column;
    height: ${prop => prop.landscape ? `${prop.height}px` : `${Math.min(prop.width + HEADER_HEIGHT_PX + TEXTBLOCK_HEIGHT_PX, prop.height - ADS_HEIGTH_PX)}px`};
`;

const LoadingDiv = styled.div`
    display:flex;
    justify-content: center;
    width: 100%;
    margin-top: 200px;
`


class HomePage extends React.Component {
    constructor(props) {
        super(props);
        this.mainRef = React.createRef();
        this.adSenseInjectorObserver = null;
        this.rootObserver = null;
        this.resizeDelay = null;
        this.state = {
            width: null,
            height: null,
        }
    }

    doUpdateDimensions = () => {
        this.setState({ width: window.innerWidth, height: window.innerHeight });
        this.resizeDelay = null;
    }

    updateDimensions = () => {
        if (this.resizeDelay == null) {
            this.resizeDelay = window.setTimeout(this.doUpdateDimensions, 100);
        }
    };

    componentDidMount() {
        if (!this.adSenseInjectorObserver && this.mainRef) {
            this.adSenseInjectorObserver = new MutationObserver((mutations, observer) => {
                this.mainRef.current.style.removeProperty('min-height');
                this.mainRef.current.style.removeProperty('height');
            });
            this.adSenseInjectorObserver.observe(this.mainRef.current, {
                attributes: true,
                attributeFilter: ['style'],
            });
        }

        this.rootObserver = new MutationObserver(function (mutations, observer) {
            const root = document.getElementById('root');
            root.style.height = "";
        });
        const root = document.getElementById('root');
        this.rootObserver.observe(root, {
            attributes: true,
            attributeFilter: ['style']
        });

        window.addEventListener('resize', this.updateDimensions);
    }

    componentWillUnmount() {
        if (this.adSenseInjectorObserver) {
            this.adSenseInjectorObserver.disconnect();
        }
        if (this.rootObserver) {
            this.rootObserver.disconnect();
        }
        window.removeEventListener('resize', this.updateDimensions);
    }

    render() {
        let { width, height } = this.state;
        width = width || window.innerWidth;
        height = height || window.innerHeight;

        const landscape = height - (HEADER_HEIGHT_PX + TEXTBLOCK_HEIGHT_PX) < width;

        return (
            <HomeDiv ref={this.mainRef} landscape={landscape}>
                <Suspense fallback={<LoadingDiv>Laddar...</LoadingDiv>}>
                    <FlexDiv {...{ width, height, landscape }}>
                        <Header landscape={landscape} />
                        <Board {...{ width, height, landscape }} />
                        <InfoBlock />
                    </FlexDiv>
                    <Ads {...{ height, landscape, width }} />
                    <MenuOverlay />
                </Suspense>
                <InfoModal />
            </HomeDiv >
        );
    }
}

export default HomePage;