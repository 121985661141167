import boardActionTypes from "./board.types";
import { checkForBingo, getShuffledBoard, initArray } from "./board.utils";

const INITIAL_STATE = {
    board: getShuffledBoard(false),
    checked: initArray(25, false),
    bingo: initArray(25, 0),
    extreme: false
}

const boardReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case boardActionTypes.SHUFFLE_BOARD:
            return {
                ...state,
                board: getShuffledBoard(state.extreme),
                checked: initArray(25, false),
                bingo: initArray(25, 0)
            }
        case boardActionTypes.CHECK_BLOCK:
            const index = action.payload;
            const checked = state.checked;
            checked[index] = !checked[index]

            const bingo = checkForBingo(checked, index);

            return {
                ...state,
                checked: checked,
                bingo,
            }
        case boardActionTypes.TOGGLE_EXTREME:
            
            return {
                ...state,
                extreme: !state.extreme,
                board: getShuffledBoard(!state.extreme),
                checked: initArray(25, false),
                bingo: initArray(25, 0)                
            }

        default:
            return state;
    }
}

export default boardReducer;