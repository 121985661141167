import React from 'react'

import './info-modal.styles.scss'

class InfoModal extends React.Component {
    state = {
        accepted: localStorage['conditions_OK'] || false
    };

    handleClick = () => {
        localStorage['conditions_OK'] = true;
        this.setState({ accepted: true })
    }

    render() {
        const { accepted } = this.state;
        const visible = !accepted;
        return (
            visible ?
                <div className="info-modal">
                    <div className="modal">
                        <div className="header">VARNING</div>
                        <div className="content">
                            <h1>Viktigt</h1>
                            <p>Spela ABSOLUT inte BingoBil när du kör bil.</p>
                            <p>Genom att klicka på OK godkänner du att denna webbsida använder cookies, för bland annat reklam.</p>
                            <h1>Regler</h1>
                            <p>Det är tillåtet att byta spelplan upp till tre gånger innan man börjar.</p>
                            <p>Kryssa för de saker du ser längs vägen.</p>
                            <p>5 i rad ger Bingo!</p>
                        </div>
                        <div className="buttons">
                            <button className="button" type="button" onClick={() => this.handleClick()}>OK</button>
                        </div>
                    </div>
                    <div className="overlay"></div>
                </div>
                :
                null

        )
    }
}

export default InfoModal;