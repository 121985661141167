import { combineReducers } from "redux";
import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import boardReducer from "./board/board.reducer";
import menuReducer from "./menu/menu.reducer";
import textblockReducer from "./textblock/textblock.reducer";

const persistConfig = {
    key: 'root',
    storage,
    whitelist: ['board']
};

const rootReducer = combineReducers({
    board: boardReducer,
    menu: menuReducer,
    textblock: textblockReducer
})

export default persistReducer(persistConfig, rootReducer)